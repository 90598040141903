 /**
* 生成文件名
* @returns
*/
export function timestamp() {
 var time = new Date();
 var y = time.getFullYear();
 var m = time.getMonth() + 1;
 var d = time.getDate();
 var h = time.getHours();
 var mm = time.getMinutes();
 var s = time.getSeconds();
 return (
   "" +
   y +
   addTime(m) +
   addTime(d) +
   addTime(h) +
   addTime(mm) +
   addTime(s)
 );
}
// 补零操作
export function addTime(m) {
 return m < 10 ? "0" + m : m;
}


// http://47.114.133.99:
//返回请求头
export function getHostUrl(){
  let ENV = process.env.NODE_ENV;
   let hostUrl = 'https://blzj.0123china.cn/api/'; 
  return hostUrl;
}

//时间戳转换
export function formatDate(val, fmt){
  let date = new Date(val);
  //console.log(date,fmt)
  let reg = fmt || 'yyyy-MM-dd hh:mm:ss';
  //console.log(reg);
  let regData = {
    'y+': date.getFullYear(),
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  };
  for (let key in regData) {
    if (new RegExp('(' + key + ')').test(reg)) {
      let value = regData[key].toString().length == 1 ? '0' + regData[key] : regData[key];
      reg = reg.replace(RegExp.$1, value);
    }
  }
  //console.log(reg)
  return reg
}






/* 对象转换成url形参 */ 
export function returnUrlParams(params){
  let keysData = Object.keys(params);
  let url = '';
  keysData.forEach((item,idx)=>{
    let val = params[item];
    url += `${idx == 0 ? '?' : ''}${item}=${val}${idx != keysData.length - 1 ?  '&' : ''}`;
  })
  return url
}

//返回图片路径
export function returnImageUrl(src){
  return  getHostUrl() +'/phone/file/download?fileName=' + src
}

// 返回图片路径
export function returnHttpImageUrl(src){
  return /(http|https):\/\/([\w.]+\/?)\S*/.test(src) ? src : returnImageUrl(src)
}