import Vue from 'vue'
import Router from 'vue-router'
// import layout from '../layout/index.vue'

import {constantRoutes} from './routers'

Vue.use(Router)


const createRouter = () => new Router({
  // mode:'history', //取消地址后面的#
  scrollBehavior: () => ({y: 0}),
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  // 重置路由
  router.matcher = newRouter.matcher
}

// 路由拦截 , 并且给路由添加名称
router.beforeEach((to, from, next) => {
  document.title = to.meta.title + ' - 总后台'
  next()
})


export default router
