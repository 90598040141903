import request from "@/utils/request";

import Qs from "qs";
import cookies from "vue-cookies";
var token = cookies.get("token");
// var uid =  localStorage.getItem("yh_id")
var uid = cookies.get("yh_id");

console.log(token, "--a--a-a-a-a-a-----");

//供应商-添加商品
export function addGoods(data) {
  return request({
    url: "merchant/addGoods",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 短信登录
export function sendSms(data) {
  console.log(data, "--ss---");
  return request({
    url: "sendSms",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });
}

export function city() {
  return request({
    url: "city.json",
    method: "get",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

export function logout() {
  return request({
    url: "/user/logout",
    method: "post",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 用户反馈列表
export function reactList() {
  return request({
    url: "/applet/reactList",
    method: "get",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 权限管理--角色列表
export function rolesList() {
  return request({
    url: "platform/rolesList",
    method: "post",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 删除角色列表信息
export function delRole(data) {
  //console.log(data)
  return request({
    url: "platform/delRole",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 添加角色
export function addRole(data) {
  return request({
    url: "platform/addRole",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 获取菜单列表（角色授权列表）
// export function menuNameList(data) {
// 	return request({
// 		url: 'menu/menuNameList',
// 		method: 'post',
// 		data: data,
// 		headers: {
// 			'Content-Type': 'application/json; charset=utf-8',
// 			'token':token,
//'id': uid
// 		}
// 	})
// }

// 获取菜单列表（角色授权列表）
export function menuNameList(data) {
  return request({
    url: "menu/menuNameList",
    method: "get",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 修改
export function updateRole(data) {
  return request({
    url: "platform/updateRole",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}
// 修改菜单
export function updateMenu(data) {
  return request({
    url: "menu/updateMenu",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}
// 角色详情
export function getInfo(data) {
  return request({
    url: "platform/getInfo",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 权限管理--菜单列表
export function menuList() {
  return request({
    url: "menu/menuList",
    method: "get",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 同步角色菜单
export function syncSupplierRole() {
  return request({
    url: "backend/syncSupplierRole",
    method: "post",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
      "jwt-token": token,
    },
  });
}

// 权限管理--菜单列表--添加菜单
export function addMenu(data) {
  //console.log(data,'--ss---')
  return request({
    url: `menu/addMenu`,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 第三方--身份认证列表
export function userAuthenticationList(params) {
  return request({
    url: "ali/userAuthenticationList",
    method: "get",
    params: params,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: "14e3e16968df6852595349f615cd44f1",
    },
  });
}

// 第三方--订单支付列表
export function orderAuthList(params) {
  return request({
    url: "ali/orderAuthList",
    method: "get",
    params: params,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: "14e3e16968df6852595349f615cd44f1",
    },
  });
}

// 账号管理--获取账号列表
export function usersList(params) {
  return request({
    url: "user/usersList",
    method: "get",
    params: params,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 账号管理--删除列表信息
export function delUser(data) {
  //console.log(data)
  return request({
    url: "user/delUser",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 账号管理--获取角色组
export function getRoles(params) {
  return request({
    url: "user/getRoles",
    method: "get",
    params: params,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 账号管理--新增账号
export function addUser(data) {
  //console.log(data)
  return request({
    url: "user/addUser",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 账号管理--编辑修改账号
export function updateUser(data) {
  //console.log(data)
  return request({
    url: "user/updateUser",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}

// 账号管理--获取单个详细信息
export function userDetail(params) {
  //console.log(params)
  return request({
    url: "user/userDetail",
    method: "get",
    params: params,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
    },
  });
}

// 账号管理--批量操作
export function setAll(data) {
  //console.log(data)
  return request({
    url: "user/setAll",
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
    },
  });
}

// getLoginUserInfo
export function getLoginUserInfo() {
  return request({
    url: "platform/getLoginUserInfo",
    method: "get",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      token: token,
      id: uid,
    },
  });
}
