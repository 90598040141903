<template>
  <div class="layout">
    <el-container>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import bus from '@/utils/bus'
import config from '@/config/platform'

const {PLATFORM, hideLayoutLogo, onlyLogo, BASE_NAME} = config
export default {
  name: 'layout',
  data() {
    return {
      breadList: [],
      dataA: [],
      account: '',
      logo: '',
      hideLayoutLogo: false,
      onlyLogo: false,
      baseName: '',
    }
  },

  created() {
    this.hideLayoutLogo = Boolean(hideLayoutLogo)
    // this.logo = require(`../../src/assets/logo_${PLATFORM}.png`)
    this.BASE_NAME = BASE_NAME
    this.onlyLogo = onlyLogo
  },

  mounted() {
    // let  _this = this
    // //console.log(this.$store.state)
    // this.dataA = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("vueX"))))
    // this.account = this.$cookies.get("account")
  },
  methods: {
    clearadd() {
      localStorage.clear()
      this.$cookies.remove("role_id")
      this.$cookies.remove("merchant_id")
      this.$cookies.remove("yh_id")
      this.$cookies.remove("account")
      this.$cookies.remove("token")
      this.$router.replace('/login')
    },

    goTo(path) {
      //console.log(path)
      this.$router.replace(path)

    },

  },
}
</script>
<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.demonstration {
  display: block;
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
}

.container_login {
  float: right;
  text-align: left;
  color: #fff;
  position: relative;
  left: -20px;
}

.container_login img {
  width: 40px;
  height: 40px;
  position: relative;
  top: 15px;
  left: -10px;
}

.top_logo {
  width: 175px;
  position: relative;
  top: 9px;
  left: 20px;
}

.top_logo_only {
  width: 55px;
  position: relative;
  top: 9px;
  left: 20px;
}

.el-header {
  padding: 0;
}

.bread_top {
  width: 100%;
  height: 40px;
  background: #fff;
  position: relative;
  top: -15px;
}

.el-breadcrumb {
  line-height: 40px;
}

.el-menu {
  border-right: solid 0px #e6e6e6;
  background: #fff;
}

.el-col-12 {
  width: 15%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
  color: #fff;
  background-color: #2b80ff;
}

.el-submenu__title:hover {
  color: #fff;
  background-color: #2b80ff;
}


.el-container{
  width: 100vw;
  height: 100vh;
}
</style>
